.product {
  flex: 4;
  padding: 20px;
}
.productTitle{
  margin-left: 2%;
}

.productTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#productTitleContainer {
  display: flex;
  align-items: center;
}
.productAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.productTop {
  display: flex;
}

.productTopLeft,
.productTopRight {
  flex: 1;
}

.productTopRight {
  padding: 20px;
  margin: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border-radius: 20px;
}

.productInfoImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}
#productInfoImg {
  width: 400px;
  height: 400px;
  border-radius: 10%;
  object-fit: contain;
  margin-right: 20px;
  margin-bottom: 20px;
  
}
#productInfoImgNotFound{
  width: 80px;
  height: 80px;
  border-radius: 10%;
  object-fit: cover;
  margin-right: 20px;
  margin-bottom: 20px;
}

.productInfoTop {
  display: flex;
  align-items: center;
}

.productName {
  /* font-weight: 600; */
  font-size:200%;
}

.productInfoBottom {
  margin-top: 10px;
  justify-content: flex-start;
}

.productInfoItem {
  font-size:130%;
  width: 500px;
  display: flex;
  justify-content: flex-start;
}

.productInfoValue {
  font-weight: 500;
}

#productInfoItem {
  font-size:130%;
  width: 100%;
  display: flex;
}

#productInfoValue {
  width: 80%;
  font-weight: 500;
  font-size:90%;
  justify-content: flex-start;
}
.productBottom {
  padding: 20px;
  margin: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.productForm {
  display: flex;
  justify-content: space-between;
}

.productFormLeft {
  display: flex;
  flex-direction: column;
}

.productFormLeft > label {
  margin-bottom: 10px;
  color: gray;
}

.productFormLeft > input {
  margin-bottom: 10px;
  border: none;
  padding: 5px;
  border-bottom: 1px solid gray;
}

.productFormLeft >select{
  margin-bottom: 10px;
}

.productUploadImg{
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px;
}

.productFormRight{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.productUpload{
  display: flex;
  align-items: center;
}

.productButton{
  border: none;
  padding: 5px;
  border-radius: 5px;
  background-color: darkblue;
  color:white;
  font-weight: 600;
  cursor: pointer;
}


.product .back_btn {
  padding: 9px 10px;
  background: #57df9e;
  border: 1px solid #57DF9E;
}

.product h1.productTitle {
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
}
.productTop .productTopRight div#productInfoItem {
  padding: 4px 0;
  border-bottom: 1px solid #dbdbdb;
}
#productInfoKey {
  width: 20%;
  font-weight: 600;
  font-size: 18px;
  justify-content: flex-start;
}

.productBottom .product_desc {
  border-bottom: 2px solid #cdcdcd;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.productBottom .product_desc:last-child {
  border-bottom: none;
} 


.productBottom .product_desc div#productInfoItem {
  border-bottom: 1px solid #dbdbdb;
  padding: 3px 0;
}

.productBottom .product_desc div#productInfoItem:last-child {
  border-bottom: none;
}