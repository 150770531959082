.userList {
  flex: 4;
  padding-right: 20px !important;
}

.userListUser {
  display: flex;
  align-items: center;
}

.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userListEdit {
  border: none;
  border-radius: 6px;
  background-color: #57df9e;
  color: white;
  cursor: pointer;
  margin-right: 0;
  text-align: center;
  width: auto;
  padding: 9px 10px;
}
.viewButton {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #57df9e;
  color: white;
  cursor: pointer;
  margin-right: 10px;
}
.deleteButton{
  
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #e4565d;
  color: white;
  cursor: pointer;
  margin-right: 0px;
}
.editButton {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #33b577;
  color: white;
  cursor: pointer;
}
#userListEditdiv{
  margin-left: 80%;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 10%;
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
}
button.next_btn, button.prev_btn {
  padding: 9px 30px;
  margin-top: 0;
  background: #57df9e !important;
  border: 1px solid #57DF9E !important;
}
.userListDelete{
    color: red;
    cursor: pointer;
}
body .search_bar_recycli {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.search_bar_recycli form input {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  padding: 10px 10px;
  border-radius: 8px 0 0 8px;
  margin-right: 0;
}

.search_bar_recycli form .search_btn {
  padding: 9px 10px;
  border-radius: 0 8px 8px 0;
  margin-top: -4px;
  background: #57df9e;
  border: 1px solid #57DF9E;
}
.search_bar_recycli form .reset_btn {
  padding: 9px 10px;
  margin-top: -4px;
  background: #57df9e;
  border: 1px solid #57DF9E;
}
.custom_data_table table.table tbody tr td:last-child, .custom_data_table table.table thead tr th:last-child {
  text-align: right;
  width: 15vw;
}
.custom_data_table table.table tbody tr td {
  vertical-align: middle;
}
.userList ul.pagination .page-link {
  font-size: 15px;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #57df9e;

}
.userList ul.pagination .page-link:focus {
  outline: 0;
  box-shadow: none;
}
.userList ul.pagination .page-link:hover {
  background-color: #57df9e;
  color: #fff;
}
.userList ul.pagination {
  margin: 0 auto;
  position: fixed;
  bottom: 30px;
  left: 20%;
  right: 0;
  margin: 0 auto;
}
.custom_data_table {
  margin-bottom: 120px;
}
.userList ul.pagination .active>.page-link, .userList ul.pagination .page-link.active {
  z-index: 3;
  color: #fff;
  background-color: #57df9e;
  border-color: #57df9e;
}
 
/* @media only screen and (max-width: 1600px) {
  .custom_data_table {
    height: 30vw;
    overflow-x: hidden;
    margin-bottom: 50px;
    display: block;
  }
  
  .custom_data_table table.table {
    overflow-y: scroll;
    height: 100%;
  }
  } */


@media only screen and (max-width: 1530px) {
.viewButton, .editButton {
  padding: 5px 5px;
  margin-right: 5px;
}
.deleteButton {
  padding: 5px;
}
}

@media only screen and (max-width: 1200px) {
  .userList ul.pagination {
    left: 0;
    right: 0;
    bottom: 10px;
}
  .custom_data_table {
    width: 100%;
    overflow: scroll;
  }
  
  .custom_data_table table.table {
    overflow-x: scroll;
    width: 1200px;
  }
  }
  @media only screen and (max-width: 900px) {
    .custom_data_table table.table tbody tr td:last-child, .custom_data_table table.table thead tr th:last-child {
      width: auto;
  }
}
    @media only screen and (max-width:767px) {
      .userList {
        padding-right: 0 !important;
    }
    .custom_data_table {
      height: auto;
      
    }
      }
  @media only screen and (max-width: 480px) {
  body .search_bar_recycli {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;
}
  }
  @media only screen and (max-width: 360px) {
  .search_bar_recycli form input {
    margin-right: 0;
    width: 180px;
}
  }