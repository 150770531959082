.topbar {
  width: 100%;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 22px;
  color: #57DF9E;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.topRight{
    display: flex;
    align-items: center;
}
.topRight button {
  padding: 9px 10px;
  background: #57df9e;
  border: 1px solid #57DF9E;
}

.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}

.topIconBadge{
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.topAvatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}