.custom_login {
    background: #fff;
    box-shadow: 0 0 8px 4px #9595951f;
    padding: 25px;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
}

.custom_login input {
    background: #ffffff !important;
    border: 1px solid #dbdbdb;
    font-size: 16px;
    padding: 13px 13px;
    color: #000;
}
.custom_login input:focus {
    color: #000;
    background-color: #fff;
    border-color: #dbdbdb;
    outline: 0;
    box-shadow: none;
}
.custom_login label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 9px;
}

.custom_login button {
    background: #57DF9E;
    border: 1px solid #57DF9E;
    padding: 8px 40px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}
.custom_login button:hover {
    background: #319162;
    border: 1px solid #319162;

}

@media screen and (max-width: 767x) {
.custom_login {
    padding: 15px;
    margin-top: 30px;
}
}