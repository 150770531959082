.userList {
  flex: 4;
  padding-right: 20px !important;
}

.userListUser {
  display: flex;
  align-items: center;
}

.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userListEdit {
  border: none;
  border-radius: 6px;
  background-color: #57df9e;
  color: white;
  cursor: pointer;
  margin-right: 0;
  text-align: center;
  width: auto;
  padding: 9px 10px;
}

.viewButton {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #57df9e;
  color: white;
  cursor: pointer;
  margin-right: 10px;
}

.deleteButton {

  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #e4565d;
  color: white;
  cursor: pointer;
  margin-right: 0px;
}

.editButton {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #33b577;
  color: white;
  cursor: pointer;
}

#userListEditdiv {
  margin-left: 80%;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 10%;
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
}

button.next_btn,
button.prev_btn {
  padding: 9px 30px;
  margin-top: 0;
  background: #57df9e !important;
  border: 1px solid #57DF9E !important;
}

.userListDelete {
  color: red;
  cursor: pointer;
}

body .search_bar_category {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.search_bar_category form input {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  padding: 10px 10px;
  border-radius: 8px 0 0 8px;
  margin-right: 0;
}

.search_bar_category form .search_btn {
  padding: 9px 10px;
  border-radius: 0 8px 8px 0;
  margin-top: -4px;
  background: #57df9e;
  border: 1px solid #57DF9E;
}

.search_bar_category form .reset_btn {
  padding: 9px 10px;
  margin-top: -4px;
  background: #57df9e;
  border: 1px solid #57DF9E;
}

.categories_table table.table tbody tr td:last-child,
.categories_table table.table thead tr th:last-child {
  /* text-align: right; */
  width: 15vw;
}

.categories_table table.table tbody tr td {
  vertical-align: middle;
  min-width: 180px;
}

.userList ul.pagination .page-link {
  font-size: 15px;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #57df9e;

}

.userList ul.pagination .page-link:focus {
  outline: 0;
  box-shadow: none;
}

.userList ul.pagination .page-link:hover {
  background-color: #57df9e;
  color: #fff;
}

.userList ul.pagination {
  margin: 0 auto;
  position: fixed;
  bottom: 10px;
  left: 20%;
  right: 0;
  margin: 0 auto;
}

.categories_table {
  margin-bottom: 120px;
  overflow-x: scroll;
  width: 100%;
}

.userList ul.pagination .active>.page-link,
.userList ul.pagination .page-link.active {
  z-index: 3;
  color: #fff;
  background-color: #57df9e;
  border-color: #57df9e;
}

.userList {
  max-width: calc(100% - 330px);
  overflow: auto;
}

.view .wrapper .table {
  height: 100%;
  max-height: 100%;
}

.view .wrapper .table thead {
  position: sticky;
  top: 0;
  z-index: 99999;
  background: #fff;
  border-bottom: 1px solid #e7e7e7;
}

.view .wrapper .table thead th {
  padding-bottom: 10px;
}

/* @media only screen and (max-width: 1600px) {
    .categories_table {
      height: 30vw;
      overflow-x: hidden;
      margin-bottom: 50px;
      display: block;
    }
    
    .categories_table table.table {
      overflow-y: scroll;
      height: 100%;
    }
    } */


@media only screen and (max-width: 1530px) {

  .viewButton,
  .editButton {
    padding: 5px 5px;
    margin-right: 5px;
  }

  .deleteButton {
    padding: 5px;
  }
}

@media only screen and (max-width: 1200px) {
  .userList ul.pagination {
    left: 0;
    right: 0;
    bottom: 10px;
  }

  .categories_table {
    width: 100%;
  }

  .categories_table table.table {
    width: 1200px;
  }
}

@media only screen and (max-width: 900px) {

  .categories_table table.table tbody tr td:last-child,
  .categories_table table.table thead tr th:last-child {
    width: auto;
  }
}

@media only screen and (max-width:767px) {
  .userList {
    padding-right: 0 !important;
  }

  .categories_table {
    height: auto;
  }
}

@media only screen and (max-width: 480px) {
  body .search_bar_category {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;
  }
}

@media only screen and (max-width: 360px) {
  .search_bar_category form input {
    margin-right: 0;
    width: 180px;
  }
}

.view {
  margin: auto;
  overflow-y: unset;
  height: 70vh;
}

.wrapper {
  position: relative;
  overflow: auto;
  white-space: nowrap;
  max-height: 100%;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white !important;
  z-index: 1000;
}

.first-col {
  min-width: 100px;
  max-width: 140px;
  left: 0px;
}

.second-col {
  min-width: 150px;
  left: 140px;
  text-align: center;
}

.third-col {
  min-width: 100px;
  max-width: 160px;
  left: 290px;
  white-space: normal;
  word-wrap: break-word;
}

.z-index-100 {
  z-index: 100 !important;
}

.z-10000 {
  position: fixed;
  z-index: 10000;
}