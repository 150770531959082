.user {
  flex: 4;
  padding: 20px;
}

.userTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.userContainer {
  display: flex;
  margin-top: 20px;
}

.userShow {
  flex: 1;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.userUpdate {
  flex: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 20px;
  border-radius: 20px;
}

.userShowTop {
  display: flex;
  align-items: center;
}

.userShowImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.userShowTopTitle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.userShowUsername {
  font-weight: 600;
}

.userShowUserTitle {
  font-weight: 300;
}

.userShowBottom{
    margin-top: 20px;
}

.userShowTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgb(175, 170, 170);
}

.userShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: #444;
}

.userShowIcon{
    font-size: 16px !important;
}

.userShowInfoTitle{
    margin-left: 10px;
}

.userUpdateTitle{
    font-size: 24px;
    font-weight: 600;
}

.userUpdateForm{
    display: 2;
    justify-content: space-between;
    margin-top: 20px;
}

.userUpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.userUpdateItem>label{
    margin-bottom: 5px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
}

.userUpdateInput {
  width: 100%;
  border: 1px solid #dbdbdb;
  font-size: 16px;
  padding: 0 10px;
  height: 45px;
  border-radius: 6px;
  background: #f7f7f7;
}
.userUpdateInputText {
  width: 100%;
  height: 8em;
  border: 1px solid #dbdbdb;
  margin-bottom: 2%;
  font-size: 16px;
  padding: 10px 10px;
  background: #f7f7f7;
  border-radius: 6px;
}
.userUpdateRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.userUpdateUpload{
    display: flex;
    align-items: center;
}

.userUpdateImg{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
}

.userUpdateIcon{
    cursor: pointer;
}

.userUpdateButton{
    border-radius: 5px;
    border: none;
    padding: 5px;
    cursor: pointer;
    background-color: darkblue;
    color: white;
    font-weight: 600;
}

.user h1.userTitle {
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
}

.userUpdateLeft button.add_new {
  padding: 10px 30px;
  background: #57df9e;
  border: 1px solid #57DF9E;
  font-size: 18px;
  font-weight: 600;
}