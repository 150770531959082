#container {
    display: flex;
    margin-top: 10px;
}


#link {
    text-decoration: none;
    color: inherit;
}

.btn-check:checked+.btn:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible,
.btn:first-child:active:focus-visible,
:not(.btn-check)+.btn:active:focus-visible {
    box-shadow: none;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    background-color: #57DF9E !important;
    border-color: #57DF9E !important;
}

.btn:focus-visible {
    color: #fff;
    background-color: #57DF9E !important;
    border-color: #57DF9E !important;
    outline: 0;
    box-shadow: none !important;
}

.btn:hover {
    color: #fff;
    background-color: #2d9d67 !important;
    border-color: #2d9d67 !important;
}

button:hover {
    background: #2d9d67 !important;
}

input:focus-visible {
    outline: 0;
    box-shadow: none;
}

textarea:focus-visible {
    outline: 0;
    box-shadow: none;
}

::-webkit-scrollbar-thumb {
    background-color: #57df9e;
}

::-webkit-scrollbar-track {
    background-color: #EFEFEF;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}