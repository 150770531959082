.sidebar {
  flex: 1 1;
  /* height: calc(100vh - 70px); */
  background-color: rgb(251, 251, 255);
  position: relative;
  top: 0;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
  position: fixed;
  top: 135px;
  bottom: 0;
  z-index: 1;
  width: 20%;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 18px;
  color: rgb(0 0 0);
  font-weight: 600;
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarList a {
  line-height: 32px;
  font-size: 15px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: 0.3s;
}

.sidebarSubListItem {
  padding: 0px 0px 0px 55px;
  /* padding: 0; */
  margin: 0px;
}

.sidebarList a:hover {
  color: #57df9e;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: #d8f9e8;
  color: #57df9e;
}

.sidebarList a.active {
  color: #57df9e;
}

.sidebarList a.active .sidebarListItem {
  background: #d8f9e8;
}

.sidebarIcon {
  margin-right: 15px;
  font-size: 20px !important;
  width: 25px;
  height: 25px;
}

@media only screen and (max-width: 1200px) {
  .sidebarWrapper {
    width: 35%;
    position: relative;
    top: 0px;
  }

  .sidebar {
    height: auto;
  }

}

@media only screen and (max-width: 991px) {
  .sidebarWrapper {
    padding: 20px 0;

  }
}

@media only screen and (max-width: 991px) {
  .sidebar {
    flex: auto;
    height: auto;
    background-color: rgb(251, 251, 255);
    position: relative;
    top: 0;
  }

  .sidebarWrapper {
    position: relative;
    top: 10px;

  }
}

@media only screen and (max-width: 767px) {
  .sidebarWrapper {
    width: 50%;
  }
}


@media only screen and (max-width: 767px) {
  .sidebarWrapper {
    width: 100%;
  }
}